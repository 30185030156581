/* modal.css */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  transform: translateY(-100%);
  transition: transform 0.3s ease;
  width: 80%;
  max-width: 80%;
  min-width: 200px;
}

.modal.show {
  opacity: 1;
  visibility: visible;
}

.modal.show .modal-content {
  transform: translateY(0);
}

.close-button {
  /* background: red; */
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.accordion-menu {
  position: absolute;
  top: 60px;
  right: 10px;
  background: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  display: none;
  flex-direction: column;
  min-width: 200px;
}

.accordion-menu.show {
  display: flex;
}

.accordion-menu a {
  padding: 10px 20px;
  color: #333;
  text-decoration: none;
  border-bottom: 1px solid #ccc;
}

.accordion-menu a:last-child {
  border-bottom: none;
}

.accordion-menu a:hover {
  background: #f0f0f0;
}
.timer {
  margin-left: 10px;
  font-size: 16px;
  color: #333;
}
